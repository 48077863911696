import axios from "axios";
import { useContext, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Context } from "../../context/Context";
import { useState } from "react";
import "./login.css";

export default function Login() {
  const userRef = useRef();
  const passwordRef = useRef();
  const { dispatch, isFetching } = useContext(Context);
  const { t } = useTranslation();
  const [error,setError] = useState("");
  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch({ type: "LOGIN_START" });
    try {
      const res = await axios.post(`${process.env.REACT_APP_API_DOMAIN}user/login`, {
        username: userRef.current.value,
        password: passwordRef.current.value,
      });
      if(res.data.code==1){
        dispatch({ type: "LOGIN_SUCCESS", payload: res.data.data.userinfo });
      }else{
        dispatch({ type: "LOGIN_FAILURE" });
        setError(res.data.msg);
      }
      
    } catch (err) {
      dispatch({ type: "LOGIN_FAILURE" });
    }
  };

  return (
    <div className="login">
      {/* <span className="loginTitle">{t('Login')}</span> */}
      <form className="loginForm" onSubmit={handleSubmit}>
        <label>{t('Username')}</label>
        <input
          type="text"
          className="loginInput"
          placeholder={t("Enter your username...")}
          ref={userRef}
        />
        <label>{t('Password')}</label>
        <input
          type="password"
          className="loginInput"
          placeholder={t("Enter your password...")}
          ref={passwordRef}
        />
        <button className="loginButton" type="submit" disabled={isFetching}>
          {t('Login')}
        </button>
      </form>
      {/* <button className="loginRegisterButton">
        <Link className="link" to="/register">
          Register
        </Link>
      </button> */}
      {error && <span style={{color:"red", marginTop:"10px"}}>{error}</span>}
    </div>
  );
}
