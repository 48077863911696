import "./settings.css";
import Sidebar from "../../components/sidebar/Sidebar";
import { useContext, useState } from "react";
import { Context } from "../../context/Context";
import axios from "axios";
import { useTranslation } from "react-i18next";
import Http from "../../components/Http";
import Request from "../../components/Request";

export default function Settings() {
  const { user, dispatch } = useContext(Context);
  const [file, setFile] = useState(null);
  const [username, setUsername] = useState(user.username);
  const [nickname, setNickname] = useState(user.nickname);
  const [email, setEmail] = useState(user.email);
  const [introduce, setIntroduce] = useState(user.bio);
  const [avatar, setAvatar] = useState(user.avatar);
  const [password, setPassword] = useState(user.password);
  const [success, setSuccess] = useState(false);
  const [err, setErr] = useState("");
  const { t } = useTranslation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSuccess(false);
    setErr("");
    dispatch({ type: "UPDATE_START" });
    const updatedUser = {
      nickname: nickname,
      email: email,
      password: password,
      bio: introduce,
      avatar: avatar
    };
    if (file) {
      const data = new FormData();
      // const filename = Date.now() + file.name;
      // data.append("name", filename);
      data.append("file", file);
      try {
        const re = await Request.uploadFile(`${process.env.REACT_APP_API_DOMAIN}common/upload`, data);
        if (re.code !== 1) {
          setErr(re.msg);
        } else {
          setAvatar(re.data.fullurl);
          updatedUser.avatar = re.data.fullurl;
        }
      } catch (err) { }
    }
    try {
      const res = await Request.postFormData(`${process.env.REACT_APP_API_DOMAIN}user/updateUserInfo`, updatedUser);
      if (res.code === 1) {
        dispatch({ type: "UPDATE_SUCCESS", payload: res.data.userinfo });
        setSuccess(true);
      } else {
        setErr(res.msg);
      }
    } catch (err) {
      dispatch({ type: "UPDATE_FAILURE" });
    }
  };
  return (
    <div className="settings">
      <div className="settingsWrapper">
        <div className="settingsTitle">
          <span className="settingsUpdateTitle">{t('Update Your Account')}</span>
          <span className="settingsDeleteTitle">{t('Delete Account')}</span>
        </div>
        <form className="settingsForm" onSubmit={handleSubmit}>
          <label>{t('Profile Picture')}</label>
          <div className="settingsPP">
            <img
              src={file ? URL.createObjectURL(file) : user.avatar}
              alt=""
            />
            <label htmlFor="fileInput">
              <i className="settingsPPIcon far fa-user-circle"></i>
            </label>
            <input
              type="file"
              id="fileInput"
              style={{ display: "none" }}
              onChange={(e) => setFile(e.target.files[0])}
            />
          </div>
          <label>{t('Username')}</label>
          <input
            type="text"
            placeholder={username}
            value={user.username}
            onChange={(e) => setUsername(e.target.value)}
            readOnly="true"
          />
          <label>{t('Nickname')}</label>
          <input
            type="text"
            placeholder={nickname}
            onChange={(e) => setNickname(e.target.value)}
          />
          <label>{t('Email')}</label>
          <input
            type="text"
            placeholder={email}
            defaultValue={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <label>{t('Password')}</label>
          <input
            type="password"
            onChange={(e) => setPassword(e.target.value)}
          />
          <label>{t('Introduce')}</label>
          <textarea
            type="text"
            placeholder={introduce}
            onChange={(e) => setIntroduce(e.target.value)}
            rows="5"
          >{introduce}</textarea>
          <button className="settingsSubmit" type="submit">
            {t('Update')}
          </button>
          {success && (
            <span
              style={{ color: "green", textAlign: "center", marginTop: "20px" }}
            >
              {t('Profile has been updated...')}
            </span>
          )}
          {err && (
            <span
              style={{ color: "red", textAlign: "center", marginTop: "20px" }}
            >
              {err}
            </span>
          )}
        </form>
      </div>
      {/* <Sidebar /> */}
    </div>
  );
}
