import axios from "axios";
import _ from "lodash";
import qs from "qs";
var service = axios.create({
    timeout: 40000,
    headers: { "Content-Type": "application/x-www-form-urlencoded" }
});
 
// //添加请求拦截器
// service.interceptors.request.use(function (config) {
//     if (store.getters.token) {
//         config.headers['TOKEN'] = getCookie('TOKEN')
//     }
//     return config
// }, function (error) {
//     return Promise.reject(error)
// })
 
const Request = {
    //get请求
    get(url, param) {
        return new Promise((resolve, reject) => {
            service({
                method: "GET",
                url,
                params: param,
                crossdomain: true,
                headers: {
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "token": sessionStorage.getItem('token')
                },
            })
                .then(res => {
                    let requestStatus = res.status;
                    if (requestStatus === 200) {
                        let data = res.data;
                        resolve(data);
                    } else {
                        reject(res.statusText);
                    }
                })
                .catch(err => {
                    reject(err.message);
                });
        });
    },
    //post请求
    post(url, param) {
        console.log(sessionStorage.getItem('token'));
        return new Promise((resolve, reject) => {
            service({
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "token": sessionStorage.getItem('token')
                },
                url,
                data: param
            })
                .then(res => {
                    let requestStatus = res.status;
                    if (requestStatus === 200) {
                        let data = res.data;
 
                        resolve(data);
                    } else {
                        reject(res.statusText);
                    }
                })
                .catch(err => {
                    reject(err.message);
                });
        });
    },
 
    postFormData(url, param) {
        return new Promise((resolve, reject) => {
            service({
                method: "POST",
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Access-Control-Allow-Origin": "*",
                    "token": sessionStorage.getItem('token')
                },
                url,
                data: param
            })
                .then(res => {
                    let requestStatus = res.status;
                    if (requestStatus === 200) {
                        let data = res.data;
                        let dataStatus = data.code;
                        if (dataStatus === 1) {
                            resolve(data);
                        } else {
                            reject(data);
                        }
                    } else {
                        console.log(res);
                        reject(res.statusText);
                    }
                })
                .catch(err => {
                    reject(err.message);
                });
        });
    },
 
    postForUrl(url, param) {
        return new Promise((resolve, reject) => {
            service({
                method: "POST",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                    "Access-Control-Allow-Origin": "*",
                    "token": sessionStorage.getItem('token')
                },
                url,
                crossdomain: true,
                params: param
            })
                .then(res => {
                    let requestStatus = res.status;
                    if (requestStatus === 200) {
                        let data = res.data;
                        let dataStatus = data.code;
                        if (dataStatus === 1) {
                            resolve(data);
                        } else {
                            reject(data);
                        }
                    } else {
                        console.log(res);
                        reject(res.statusText);
                    }
                })
                .catch(err => {
                    reject(err.message);
                });
        });
    },
 
    uploadFile(url, param) {
        return new Promise((resolve, reject) => {
            service({
                method: "POST",
                url,
                data: param,
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Access-Control-Allow-Origin": "*",
                    "token": sessionStorage.getItem('token')
                },
            })
                .then(res => {
                    let requestStatus = res.status;
                    if (requestStatus === 200) {
                        let data = res.data;
                        let dataStatus = data.code;
                        if (dataStatus === 1) {
                            resolve(data);
                        } else {
                            reject(data);
                        }
                    } else {
                        console.log(res);
                        reject(res.statusText);
                    }
                })
                .catch(err => {
                    reject(err.message);
                });
        });
    }
};

export default Request;