import React from "react";
// import '../App.css';
import Footer from '../../components/footer/Footer';
import Posts from "../../components/posts/Posts";
import Sidebar from "../../components/sidebar/Sidebar";
import Header from "../../components/header/Header";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import "../Other.css";
import { useContext } from "react";
import { Context } from "../../context/Context";
import Request from "../../components/Request";
import { useLocation } from "react-router-dom";
export default function Bloglist() {
    const location = useLocation();
    const search = location.search;
    const { t } = useTranslation();
    const [posts, setPosts] = useState([]);
    const { user, dispatch } = useContext(Context);
    const API_DOMAIN = process.env.REACT_APP_API_DOMAIN;
    useEffect(() => {
        const fetchPosts = async () => {
            let res = [];
            if (search) {
                res = await Request.get(`${process.env.REACT_APP_API_DOMAIN}blog/getList${search}`);
            } else {
                res = await Request.get(`${process.env.REACT_APP_API_DOMAIN}blog/getList?user_id=` + user.id);
            }
            if (res.code) {
                setPosts(res.data);
            }
        }
        fetchPosts();
    }, [])

    return (
        <>
            {/* <h1 className="kit">Kit</h1> */}
            <Header title={t('Bloglist')} subtitle={t('The list of content I have posted')} themebg="/images/life-work.jpg" />
            {posts.length > 0 && <div className="home">
                <Posts blogs={posts} />
                {/* <Sidebar /> */}
            </div>}
            <Footer />
        </>

    )
}