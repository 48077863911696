import React from 'react';
import Navbar from './components/navbar/Navbar';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Home from './pages/Home';
import AI from './pages/AI';
import Knowledge from './pages/Knowledge';
import Kit from './pages/Kit';
import Cherish from './pages/Cherish';
import Life from './pages/Life';
import Login from './pages/login/Login';
import Register from './pages/register/Register';
import Settings from './pages/settings/Settings';
import Single from './pages/single/Single';
import Write from './pages/write/Write';
import Bloglist from './pages/bloglist/Bloglist';
import { useContext } from "react";
import { Context } from "./context/Context";

function App() {
  const { user } = useContext(Context);
  return (
    <>
      <Router>
        <Navbar />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/ai' element={<AI />} />
          <Route path='/knowledge' element={<Knowledge />} />
          <Route path='/kit' element={<Kit />} />
          <Route path='/life' element={<Life />} />
          <Route path='/cherish' element={<Cherish />} />
          <Route path="/register" element={user ? <Bloglist /> : <Register />} />
          <Route path="/login" element={user ? <Bloglist /> : <Login />} />
          <Route path="/write" element={user ? <Write /> : <Register />} />
          <Route path="/settings" element={user ? <Settings /> : <Register />} />
          <Route path="/post/:postId" element={<Single />} />
          <Route path="/bloglist" element={<Bloglist />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
