import React, { useTransition } from "react";
import '../../App.css';
import { Button } from '../button/Button';
import './HeroSection.css';
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import { Context } from "../../context/Context";

function HeroSection() {
    const { t } = useTranslation();
    const { user, dispatch } = useContext(Context);
    return (
        <div className="hero-container">
            {/* <video src="/videos/video-2.mp4" autoPlay loop muted /> */}
            <h1>{t('Discover and explore the unknown, learn and adapt to the world')}</h1>
            <p>{t('What are you waiting for?')}{t('What are you looking for?')}</p>
            {!user ? (<div className="hero-btns">
                <Button
                    className='btns'
                    buttonStyle='btn--outline'
                    buttonSize='btn--large'
                    to="register"
                >
                    {t('Register')}
                </Button>
                <Button
                    className='btns'
                    buttonStyle='btn--primary'
                    buttonSize='btn--large'
                    // onClick={console.log('hey')}
                    to="login"
                >
                    {t('Login')} <i className='far fa-play-circle' />
                </Button>
            </div>)
            :(
                <Button
                    className='btns'
                    buttonStyle='btn--primary'
                    buttonSize='btn--large'
                    onClick={console.log('hey')}
                >
                    {t('Welcome')}-{user.nickname} 
                    {/* <i className='far fa-play-circle' /> */}
                </Button>
            )
        
        
            }
        </div>
    )
}

export default HeroSection;