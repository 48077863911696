import React, { useEffect } from "react";
import { useTranslation } from 'react-i18next';
import i18next from 'i18next'
import cookies from 'js-cookie'
import classNames from 'classnames'
import 'bootstrap/dist/js/bootstrap.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'flag-icon-css/css/flag-icons.min.css';
import './navbar/Navbar.css';
import { useContext } from "react";
import { Context } from "../context/Context";



const options = [
    {
        code: 'Space',
        name: 'Space',
        to: '/bloglist',
        icon: 'bi-rocket-takeoff'
    },
    {
        code: 'Setting',
        name: 'Setting',
        to: '/settings',
        icon: 'bi-gear-fill'
    },
    {
        code: 'Write',
        name: 'Write',
        to: '/write',
        icon: 'bi-pen'
    },
    {
        code: 'Logout',
        name: 'Logout',
        to: '/login',
        icon: 'bi-box-arrow-right'
    },
]


function UserSetting() {
    //语言
    const { t } = useTranslation()
    const PF = "http://localhost:5000/images/";
    const { user, dispatch } = useContext(Context);
    // const releaseDate = new Date('2021-03-07')
    // const timeDifference = new Date() - releaseDate
    // const number_of_days = Math.floor(timeDifference / (1000 * 60 * 60 * 24))
    const handleLogout = () => {
        dispatch({ type: "LOGOUT" });
    };
    return (
        <>
            <div className="dropdown-center">
                {/* <button
                    className="btn btn-link dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                >
                </button> */}
                <img className="topImg" src={user.avatar} alt="" type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false" />
                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                    {options.map(({ code, name, to, icon }) => (
                        <li className="dropdown-usersetting-li dropdown-item d-flex gap-2 align-items-center">
                            <a className="link"
                                onClick={() => {
                                    code != 'Logout' ? window.location.replace(to) : handleLogout()
                                }}
                            >
                                <i className={`bi ${icon} setting-icon`}></i>
                                {t(`${name}`)}
                            </a>
                        </li>
                    ))}
                </ul>
            </div>
        </>
    )
}

export default UserSetting;
