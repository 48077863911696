import Post from "../post/Post";
import "./posts.css";

export default function Posts({ blogs }) {
  return (
    <div className='cards'>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            {blogs.map(p => (
              <Post post={p} />
            ))}

            {/* <div className="singlePostAdd">
          <i
            className="writeIcon fas fa-plus"
            onClick={() => window.location.replace('/write')}
          ></i>
        </div> */}
          </ul>
        </div>
      </div>
    </div >
  );
}
