import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import { Context } from "../../context/Context";
import Request from "../Request";
import "./singlePost.css";

export default function SinglePost() {
  const location = useLocation();
  const path = location.pathname.split("/")[2];
  const [post, setPost] = useState([]);
  const { user } = useContext(Context);
  const [title, setTitle] = useState("");
  const [cat, setCat] = useState(0);
  const [content, setContent] = useState("");
  const [updateMode, setUpdateMode] = useState(false);
  const [file, setFile] = useState(null);
  const [author, setAuthor] = useState([]);
  const [top_img, setTopImg] = useState("");
  const cat_conf = { "14": "AI", "15": "Knowledge", "16": "Kit", "17": "Life", "18": "Chrish" };
  const cat_conf1 = [{ "code": 14, "name": "AI" }, { "code": "15", "name": "Knowledge" }, { "code": "16", "name": "Kit" }, { "code": "17", "name": "Life" }, { "code": "18", "name": "Chrish" }];

  useEffect(() => {
    const getPost = async () => {
      const res = await Request.get(`${process.env.REACT_APP_API_DOMAIN}blog/getData?id=` + path);
      if (res.code) {
        setPost(res.data);
        setTitle(res.data.title);
        setContent(res.data.content);
        setAuthor(res.data.author);
        setTopImg(res.data.top_img);
        setCat(res.data.category_id == null ? 14 : res.data.category_id);
      }
    };
    getPost();
  }, [path]);

  const handleDelete = async () => {
    try {
      await axios.delete(`/posts/${post._id}`, {
        data: { username: user.username },
      });
      window.location.replace("/");
    } catch (err) { }
  };

  const closeUpdate = async () => {
    setUpdateMode(false);
  }

  const handleUpdate = async (e) => {
    e.preventDefault();

    const newPost = {
      id: post.id,
      title: title,
      content: content,
      top_img: top_img,
      category_id: cat
    };

    if (file) {
      const data = new FormData();
      const filename = Date.now() + file.name;
      // data.append("name", filename);
      data.append("file", file);
      try {
        let re = await Request.uploadFile(`${process.env.REACT_APP_API_DOMAIN}common/upload`, data);
        console.log("上传成功");
        console.log(re);
        if (re.code === 1) {
          console.log("设置图片" + re.data.fullurl);
          setTopImg(re.data.fullurl);
          newPost.top_img = re.data.fullurl;
        }
      } catch (err) { }
    }

    try {
      const res = await Request.postFormData(`${process.env.REACT_APP_API_DOMAIN}blog/updateBlog`, newPost);
      if (res.code === 1) {
        setUpdateMode(false);
      }
    } catch (err) { }
  };

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="singlePost">
      <div className="singlePostWrapper">
        {updateMode && (<label className="singlePostPicEditBtn" htmlFor="fileInput">
          <i className="singlePostIcon far fa-edit"></i>
        </label>
        )}
        <input
          type="file"
          id="fileInput"
          style={{ display: "none" }}
          onChange={(e) => setFile(e.target.files[0])}
        />
        <img src={file ? URL.createObjectURL(file) : post.top_img} alt="" className="singlePostImg" />

        {updateMode ? (
          <input
            type="text"
            value={title}
            className="singlePostTitleInput"
            autoFocus
            onChange={(e) => setTitle(e.target.value)}
          />
        ) : (
          <h1 className="singlePostTitle">
            {title}
            {post.user_id === user?.id && (
              <div className="singlePostEdit">
                <i
                  className="singlePostIcon far fa-edit"
                  onClick={() => setUpdateMode(true)}
                ></i>
                <i
                  className="singlePostIcon far fa-trash-alt"
                  onClick={handleDelete}
                ></i>
              </div>
            )}
          </h1>
        )}
        <div className="singlePostInfo">
          <span className="singlePostAuthor">
            Author:
            <Link to={`/?user=${author.id}`} className="link">
              <b> {author.nickname}</b>
            </Link>

            <span>Category:{cat_conf[cat]}</span>
          </span>
          <span className="singlePostDate">
            {new Date(post.createtime * 1000).toDateString()}
          </span>
        </div>

        {updateMode && (
          <select class="form-select form-select-lg mb-4" aria-label=".form-select-lg example"
            onChange={e => setCat(e.target.value)}>
            {cat_conf1.map(_cat =>
              <option selected={cat === _cat.code ? true : false} value={_cat.code}>{_cat.name}</option>
            )}
          </select>)}
        {updateMode ? (
          <textarea
            className="singlePostDescInput"
            rows="15"
            onChange={(e) => setContent(e.target.value)}>{content}</textarea>
        ) : (
          <pre className="singlePostDesc">{content}</pre>
        )}
        {updateMode &&
          (<button className="singlePostButton" onClick={handleUpdate}>
            Update
          </button>)
        }
        {updateMode &&
          (<button className="singlePostButton" onClick={closeUpdate}>
            Back
          </button>)
        }
      </div>
    </div>
  );
}
