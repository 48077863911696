import Sidebar from "../../components/sidebar/Sidebar";
import SinglePost from "../../components/singlePost/SinglePost";
import "./single.css";
import { useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import Footer from "../../components/footer/Footer";
import Request from "../../components/Request";

export default function Single() {
  const [author, setAuthor] = useState('');
  const location = useLocation();
  const path = location.pathname.split("/")[2];
  // const [blog, setBlog] = useState({});
  // useEffect(() => {
  //   const getBlog = async () => {
  //     const res = await Request.get(`${process.env.REACT_APP_API_DOMAIN}blog/getData?id=` + path);
  //     setBlog(res.data.data);
  //     setAuthor(res.data.data.author);
  //   };

  //   getBlog();
  // }, [path]);

  return (
    <>
      <div className="single">
        <SinglePost />
        <Sidebar />
      </div>
      
      <Footer />
    </>
  );
}
