import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useContext } from "react";
import { Context } from "../../context/Context";
import { useLocation } from "react-router-dom";
import "./sidebar.css";
import Request from "../Request";

export default function Sidebar() {
  const location = useLocation();
  const path = location.pathname.split("/")[2];
  const [cats, setCats] = useState([]);
  const {user, dispatch } = useContext(Context);
  const [author, setAuthor] = useState([]);

  useEffect(() => {
    const getAuthor1 = async () => {
      console.log('获取用户数据')
      const res1 = await Request.get(`${process.env.REACT_APP_API_DOMAIN}blog/getAuthor?blog_id=` + path);
      console.log(res1.data);
      setAuthor(res1.data);
    };
    getAuthor1();
  }, [path]);


  useEffect(() => {
    console.log(author);
    const getCats = async () => {
      const res = await Request.get(`${process.env.REACT_APP_API_DOMAIN}blog/getCategories?user_id=${author.id}`);
      setCats(res.data);
    };
    if(author)getCats();
  }, [author]);
  return (
    <div className="sidebar">
      <div className="sidebarItem">
        <span className="sidebarTitle">ABOUT AUTHOR</span>
        <img
          src={author.avatar}
          alt=""
        />
        <span className="sidebarNickname">
          {author.nickname}
        </span>
        <p>
          {author.bio}
        </p>
      </div>
      <div className="sidebarItem">
        <span className="sidebarTitle">AUTHOR CATEGORIES</span>
        <ul className="sidebarList">
          {cats.map((c,index) => (
            <Link to={`/bloglist?user_id=${author.id}&category_id=${c.category_id}`} className="link">
              <li className="sidebarListItem" key={index}>{c.name}</li>
            </Link>
          ))}
        </ul>
      </div>
      <div className="sidebarItem">
        <span className="sidebarTitle">FOLLOW AUTHOR</span>
        <div className="sidebarSocial">
          <i className="sidebarIcon fab fa-facebook-square"></i>
          <i className="sidebarIcon fab fa-twitter-square"></i>
          <i className="sidebarIcon fab fa-pinterest-square"></i>
          <i className="sidebarIcon fab fa-instagram-square"></i>
        </div>
      </div>
    </div>
  );
}
