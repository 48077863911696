import { useContext, useState } from "react";
import "./write.css";
import axios from "axios";
import { Context } from "../../context/Context";
import { useTranslation } from "react-i18next";
import Http from "../../components/Http";
import Request from "../../components/Request";

export default function Write() {
  const [title, setTitle] = useState("");
  const [summary, setSummary] = useState("");
  const [desc, setDesc] = useState("");
  const [file, setFile] = useState(null);
  const [cat, setCat] = useState(14);
  const { user } = useContext(Context);
  const { t } = useTranslation();

  const catList = ["ai", "knowledge", "kit", "life", "chrish"]

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newPost = {
      username: user.username,
      title: title,
      summary: summary,
      content: desc,
      categories: cat
    };
    if (file) {
      const data = new FormData();
      // const filename = Date.now() + file.name;
      // data.append("name", filename);
      data.append("file", file);
      try {
        const re = await Request.uploadFile(`${process.env.REACT_APP_API_DOMAIN}common/upload`, data);
        console.log(re);
        if (re.code === 1) {
          newPost.top_img = re.data.fullurl;
        }
      } catch (err) { }
    }

    try {
      const res = await Request.postFormData(`${process.env.REACT_APP_API_DOMAIN}blog/postBlog`, newPost);
      if (res.code === 1) {
        window.location.replace("/post/" + res.data.id);
      }
    } catch (err) { }
  };
  return (
    <div className="write">
      {file && (
        <img className="writeImg" src={URL.createObjectURL(file)} alt="" />
      )}
      <form className="writeForm" onSubmit={handleSubmit}>
        <div className="writeFormGroup">
          <label htmlFor="fileInput">
            <i className="writeIcon fas fa-plus"></i>
          </label>
          <input
            type="file"
            id="fileInput"
            style={{ display: "none" }}
            onChange={(e) => setFile(e.target.files[0])}
          />
          <input
            type="text"
            placeholder={t("Title")}
            className="writeInput"
            autoFocus={true}
            onChange={e => setTitle(e.target.value)}
          />
        </div>
        <input
          type="text"
          placeholder={t("Summery")}
          className="writeInput"
          autoFocus={true}
          onChange={e => setSummary(e.target.value)}
        />
        <select class="form-select form-select-lg mb-4" aria-label=".form-select-lg example"
          onChange={e => setCat(e.target.value)}>
          <option selected value="14">AI</option>
          <option value="15">Knowledge</option>
          <option value="16">Kit</option>
          <option value="17">Life</option>
          <option value="18">Chrish</option>
        </select>
        <div className="writeFormGroup">
          <textarea
            placeholder={t("Tell your story...")}
            type="text"
            className="writeInput writeText"
            onChange={e => setDesc(e.target.value)}
          ></textarea>
        </div>
        <button className="writeSubmit" type="submit">
          {t('Publish')}
        </button>
      </form>
    </div>
  );
}
