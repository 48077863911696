import React from "react";
// import '../App.css';
import Footer from "../components/footer/Footer";
import Posts from "../components/posts/Posts";
import Sidebar from "../components/sidebar/Sidebar";
import Header from "../components/header/Header";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import "./Other.css";
import { useLocation } from "react-router-dom";
import Request from "../components/Request";
export default function Kit() {
    const { t } = useTranslation();
    const [posts, setPosts] = useState([]);
    const { search } = useLocation();

    useEffect(() => {
        const fetchPosts = async () => {
            const res = await Request.get(`${process.env.REACT_APP_API_DOMAIN}blog/getListByCat?cat=18`);
            setPosts(res.data);
        };
        fetchPosts();
    }, [search]);

    return (
        <>
            {/* <h1 className="kit">Kit</h1> */}
            <Header title={t('Chrish')} subtitle={t('Cherish the world and be grateful for you')} themebg="/images/grateful-write.jpg" />
            {posts.length > 0 && <div className="home">
                <Posts blogs={posts} />
                {/* <Sidebar /> */}
            </div>}
            <Footer />
        </>

    )
}