import React from 'react';
import './Footer.css';
import { Button } from '../button/Button';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function Footer() {
  const {t} = useTranslation();
  return (
    <div className='footer-container'>
      <section className='footer-subscription'>
        <p className='footer-subscription-heading'>
          {t('Join the 5mworld newsletter to get our best deals on tool kits and the latest news')}
        </p>
        <p className='footer-subscription-text'>
          {t('You can unsubscribe at any time')}
        </p>
        <div className='input-areas'>
          <form>
            <input
              className='footer-input'
              name='email'
              type='email'
              placeholder={t('Your Email')}
            />
            <Button buttonStyle='btn--outline'>{t('Subscribe')}</Button>
          </form>
        </div>
      </section>
      <div class='footer-links'>
        <div className='footer-link-wrapper'>
          <div class='footer-link-items'>
            <h2>{t('About Us')}</h2>
            <Link to='/sign-up'>{t('How it works')}</Link>
            <Link to='/'>{t('Testimonials')}</Link>
            <Link to='/'>{t('Careers')}</Link>
            <Link to='/'>{t('Investors')}</Link>
            <Link to='/'>{t('Terms of Service')}</Link>
          </div>
          <div class='footer-link-items'>
            <h2>{t('Contact Us')}</h2>
            <Link to='/'>{t('Contact')}</Link>
            <Link to='/'>{t('Support')}</Link>
            <Link to='/'>{t('Destinations')}</Link>
            <Link to='/'>{t('Sponsorships')}</Link>
          </div>
        </div>
        <div className='footer-link-wrapper'>
          {/* <div class='footer-link-items'>
            <h2>Videos</h2>
            <Link to='/'>Submit Video</Link>
            <Link to='/'>Ambassadors</Link>
            <Link to='/'>Agency</Link>
            <Link to='/'>Influencer</Link>
          </div> */}
          <div class='footer-link-items'>
            <h2>{t('Social Media')}</h2>
            <Link to='/'>{t('Instagram')}</Link>
            <Link to='/'>{t('Facebook')}</Link>
            <Link to='/'>{t('Youtube')}</Link>
            <Link to='/'>{t('Twitter')}</Link>
          </div>
        </div>
      </div>
      <section class='social-media'>
        <div class='social-media-wrap'>
          <div class='footer-logo'>
            <Link to='/' className='social-logo'>
            {t('5MWORLD')}
            <img src='/favicon.ico' className='navbar-logo-icon' />
              {/* <i class='fab fa-typo3' /> */}
            </Link>
          </div>
          <small class='website-rights'>5MWORLD © 2023</small>
          <div class='social-icons'>
            <Link
              class='social-icon-link facebook'
              to='/'
              target='_blank'
              aria-label='Facebook'
            >
              <i class='fab fa-facebook-f' />
            </Link>
            <Link
              class='social-icon-link instagram'
              to='/'
              target='_blank'
              aria-label='Instagram'
            >
              <i class='fab fa-instagram' />
            </Link>
            <Link
              class='social-icon-link youtube'
              to='/'
              target='_blank'
              aria-label='Youtube'
            >
              <i class='fab fa-youtube' />
            </Link>
            <Link
              class='social-icon-link twitter'
              to='/'
              target='_blank'
              aria-label='Twitter'
            >
              <i class='fab fa-twitter' />
            </Link>
            <Link
              class='social-icon-link twitter'
              to='/'
              target='_blank'
              aria-label='LinkedIn'
            >
              <i class='fab fa-linkedin' />
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Footer;