import React, { useState, useEffect } from 'react';
import { Button } from '../button/Button';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './Navbar.css';
import LangSelector from '../LangSelector';
import { useContext } from "react";
import { Context } from "../../context/Context";
import UserSetting from '../UserSetting';

function Navbar() {
  const { t } = useTranslation();

  const { user, dispatch } = useContext(Context);
  const PF = "http://localhost:5000/images/"

  const handleLogout = () => {
    dispatch({ type: "LOGOUT" });
  };
  //交互
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  useEffect(() => {
    showButton();
  }, []);

  window.addEventListener('resize', showButton);

  return (
    <>
      <nav className='navbar'>
        <div className='navbar-container'>
          <LangSelector />
          {/* <Link to='/' className='navbar-logo' onClick={closeMobileMenu}> */}
          <span>{t('5MWORLD')}</span>
          {/* <i className='fab fa-typo3' /> */}
          {!user && <img src='/favicon.ico' className='navbar-logo-icon' />}
          {/* </Link> */}
          <div className='menu-icon' onClick={handleClick}>
            <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
          </div>
          <ul className={click ? 'nav-menu active' : 'nav-menu'}>
            <li className='nav-item'>
              <Link to='/' className='nav-links' onClick={closeMobileMenu}>
                {t('Home')}
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                to='/ai'
                className='nav-links'
                onClick={closeMobileMenu}
              >
                {t('AI')}
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                to='/knowledge'
                className='nav-links'
                onClick={closeMobileMenu}
              >
                {t('Knowledge')}
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                to='/kit'
                className='nav-links'
                onClick={closeMobileMenu}
              >
                {t('Kit')}
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                to='/life'
                className='nav-links'
                onClick={closeMobileMenu}
              >
                {t('Life')}
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                to='/cherish'
                className='nav-links'
                onClick={closeMobileMenu}
              >
                {t('Cherish')}
              </Link>
            </li>
            {/* <Link
              to='/cherish'
              className='nav-links'
              onClick={handleLogout}
            >
              {user && "LOGOUT"}
            </Link> */}
            {!user &&
              <li>
                <Link
                  to='/register'
                  className='nav-links-mobile'
                  onClick={closeMobileMenu}
                >
                  {t('SignUp')}
                </Link>
              </li>}
            {!user && <li>
              <Link
                to='/login'
                className='nav-links-mobile'
                onClick={closeMobileMenu}
              >
                {t('Login')}
              </Link>
            </li>
            }


          </ul>

          {user ? (
            // <Link className="link" to="/settings">
            //   <img className="topImg" src={PF+user.profilePic} alt="" />
            // </Link>
            <UserSetting />
          ) : (
            button &&
            <>
              {/* <li className="topListItem">
                <Link className="link" to="/login">
                  LOGIN
                </Link>
              </li>
              <li className="topListItem">
                <Link className="link" to="/register">
                  REGISTER
                </Link>
              </li> */}
              {/* <Button buttonStyle='btn--outline' to="register">{t('SignUp')}</Button>
              <Button buttonStyle='btn--outline' to="login">{t('Login')}</Button> */}
            </>
          )}


        </div>
      </nav>
    </>
  );
}

export default Navbar;