import React from "react";
import CardItem from '../carditem/CardItem';
import './Cards.css'
import { useTranslation } from "react-i18next";

function Cards() {
  const {t} = useTranslation();
    return (
      <div className='cards'>
        <h1>{t('Check Out These Epic Things That Have Happened Today!')}</h1>
        <div className='cards__container'>
          <div className='cards__wrapper'>
            <ul className='cards__items'>
              <CardItem
                src='images/ai-tec.jpg'
                text={t('AI technology changes the world, it will be a new industrial revolution')}
                label={t('AI TEC')}
                path='/ai'
              />
              <CardItem
                src='images/knowledge-manypp.jpg'
                text={t('Find knowledge to solve problems in life')}
                label={t('Knowledge')}
                path='/knowledge'
              />
            </ul>
            <ul className='cards__items'>
              <CardItem
                src='images/kit-handbowl.jpg'
                text={t('A variety of toolkits and complete sets of content help to get twice the result with half the effort')}
                label={t('Kit')}
                path='/kit'
              />
              <CardItem
                src='images/life-city.jpg'
                text={t('Everyone has a lot of experience in life, to taste and continue to accumulate and understand it')}
                label={t('Life')}
                path='/life'
              />
              <CardItem
                src='images/grateful-cookie.jpg'
                text={t('Cherish the world and be grateful for you')}
                label={t('Cherish')}
                path='/cherish'
              />
            </ul>
          </div>
        </div>
      </div>
    );
  }
  
  export default Cards;